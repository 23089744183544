import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";

import Link from "../link";

import Icon from "./icon.svg";

const Container = styled.span`
    ${tw`text-white no-underline flex items-center leading-tight light-mode:text-gray`};
`;

const Logo = styled(Icon)`
    ${tw`fill-current text-primary-light hover:text-white light-mode:text-primary light-mode:hover:text-primary`};
    width: 36px;
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: 768px) {
        width: 47px;
    }
`;

const Written = styled.span`
    ${tw`block pl-3`};
`;

const Name = styled.span`
    ${tw`font-sansbold antialiased block text-xl`};
`;

const Position = styled.span`
    ${tw`text-xs font-sans block md:text-sm`};
`;

const StyledLink = styled(Link)`
    ${tw`no-underline`};
`;

const Brand = () => (
        <Container>
            <StyledLink to="/">
                <Logo />
            </StyledLink>
            <Written>
                <Name>Leonardo Giacone</Name>
                <Position>funk-stack developer @ <StyledLink to="https://www.webiny.com" target="_blank">webiny</StyledLink></Position>
            </Written>
        </Container>
);

export default Brand;
