import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";

import Container from "../container";
import Link from "../link";

const FooterStyled = styled.footer`
    ${tw`py-8 mt-4 font-sans text-sm lg:py-16 lg:mt-12`};
`;

const Footer = () => (
    <FooterStyled>
        <Container>
            © 2024 - Leonardo Giacone -{" "}
            <Link className="underline" to="/cookie-policy/">
                Cookie policy
            </Link>
        </Container>
    </FooterStyled>
);

export default Footer;
