import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";

import Container from "../container";
import Brand from "../brand";

const Inner = styled.div`
    ${tw`py-6 mb-4 md:py-16 lg:mb-10`};
`;

const Header = () => (
    <header>
        <Container>
            <Inner>
                <Brand />
            </Inner>
        </Container>
    </header>
);

export default Header;
