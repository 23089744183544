import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";

const Metadata = ({ title, description }) => {
    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata {
                    title
                    description
                    siteUrl
                }
            }
        }
    `);

    const defaults = data.site.siteMetadata;
    const seo_title = title || defaults.title;
    const seo_description = description || defaults.description;
    const url = new URL(defaults.siteUrl);

    return (
        <Helmet defer={false}>
            <title>{seo_title}</title>
            <link rel="canonical" href={url} />
            <meta name="description" content={seo_description} />
        </Helmet>
    );
};

Metadata.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};

export default Metadata;
