import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tw from "tailwind.macro";

const Container = styled.div`
    ${tw`bg-primary-darker text-white font-serif light-mode:bg-gray-lightest light-mode:text-gray`};

    h1 {
        ${tw`font-sansbold antialiased leading-none mb-6 text-5xl md:text-7xl xl:mb-12 light-mode:text-primary-darker`}
    }

    h2 {
        ${tw`font-sansbold antialiased leading-none text-3xl mb-4 mt-8 md:mb-6 md:mt-10 md:text-5xl light-mode:text-primary-darker`}
    }

    h3 {
        ${tw`font-sansbold antialiased leading-tight text-xl mb-3 mt-8 md:text-3xl md:mb-4 md:mt-8 light-mode:text-primary-darker`}
    }

    h4 {
        ${tw`font-sansbold antialiased leading-tight text-lg mb-2 mt-4 md:text-2xl md:mb-3 md:mt-6 light-mode:text-primary-darker`}
    }

    h5 {
        ${tw`font-sansbold antialiased light-mode:text-primary-darker`}
    }

    h6 {
        ${tw`font-sansbold antialiased light-mode:text-primary-darker`}
    }

    p {
        ${tw`text-sm mb-2 md:text-base md:mb-4`}
    }

    ul {
        ${tw`text-sm pl-6 list-disc md:text-base md:pl-8`}

        li {
            ${tw`mb-2 md:mb-4`}
        }
    }

    b,
    strong {
        ${tw`font-serifbold light-mode:text-primary-darker`}
    }

    i,
    em {
        ${tw`font-serifitalic`}
    }

    i strong,
    em strong,
    i b,
    em b {
        ${tw`font-serifbolditalic light-mode:text-primary-darker`}
    }

    a {
        ${tw`font-underline`}
    }
`;

const Layout = ({ children, cssClass }) => (
    <Container className={`${cssClass}`}>{children}</Container>
);

Layout.propTypes = {
    children: PropTypes.node,
    cssClass: PropTypes.string
};

export default Layout;
