import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Container = ({ children }) => {
    const containerClass = classNames({
        container: true,
        "mx-auto": true,
        "px-5": true
    });
    return <section className={containerClass}>{children}</section>;
};

Container.propTypes = {
    children: PropTypes.node
};
export default Container;
